export default {
  params: [
    {
      parameter: 'access_key',
      required: true,
      default: null,
      description:
        'A valid access key allowing you to make API calls. Access keys can be managed from your dashboard.',
    },
    {
      parameter: 'url',
      required: true,
      default: null,
      description: 'The URL of the website you wish to take a screenshot of.',
    },
    {
      parameter: 'timeout',
      required: true,
      default: 30,
      description:
        'How long to wait (in seconds) for the requested URL to response.',
    },
    {
      parameter: 'ttl',
      required: false,
      default: 86400,
      description:
        'The amount of seconds that a screenshot will be cached. This means that requesting the same screenshot within the TTL period will not count towards your usage. Range is from 0 seconds to 2592000 seconds (30 days).',
    },
    {
      parameter: 'force',
      required: false,
      default: false,
      description: 'Generate a new screenshot even if it is cached.',
    },
    {
      parameter: 'full_page',
      required: false,
      default: false,
      description:
        'Set to true if you would like to capture the full page of the website.',
    },
    {
      parameter: 'width',
      required: false,
      default: 1920,
      description: 'Width (in pixels) of the browser viewport.',
    },
    {
      parameter: 'height',
      required: false,
      default: 1080,
      description: 'Height (in pixels) of the browser viewport.',
    },
    {
      parameter: 'delay',
      required: false,
      default: 0,
      description:
        'Delay (in seconds) to wait before the screenshot will be taken. Range: 0 seconds to 10 seconds.',
    },
    {
      parameter: 'wait_for_selector',
      required: false,
      default: null,
      description:
        'Wait until the provided CSS selector matches within the webpage before taking a screenshot. Default timeout is 20 seconds.',
    },
    {
      parameter: 'selector',
      required: false,
      default: null,
      description:
        'Takes a screenshot of the first match of the given CSS selector. Default timeout is 20 seconds.',
    },
    {
      parameter: 'wait_timeout',
      required: false,
      default: 20,
      description:
        'Time (in seconds) before `waitForSelector` or `selector` will timeout. Range: 0 seconds to 45 seconds.',
    },
    {
      parameter: 'format',
      required: false,
      default: 'jpeg',
      description: 'Format of the screenshot. Can be `jpeg` or `png`',
    },
    {
      parameter: 'quality',
      required: false,
      default: 85,
      description:
        'Image quality of the screenshot. Only works with `jpeg`. Range: 0 to 100',
    },
    {
      parameter: 'transparent',
      required: false,
      default: false,
      description:
        "Hide the website's default background and the image will have a transparent background. Only works with `png`",
    },
    {
      parameter: 'thumbnail_width',
      required: false,
      default: null,
      description:
        'Width (in pixels) of the thumbnail to be created. This option will be ignored if `fullPage` is set to true',
    },
    {
      parameter: 'retina',
      required: false,
      default: false,
      description:
        'This will increase create a high definition screenshot that is suited well for retina devices. Since this is a heavy task, it will take slightly longer to generate.',
    },
    {
      parameter: 'css',
      required: false,
      default: null,
      description: 'You can provide custom CSS by using a URL encoded string.',
    },
    {
      parameter: 'js',
      required: false,
      default: null,
      description:
        'You can provide custom JavaScript by using a URL encoded string.',
    },
    {
      parameter: 'accept_language',
      required: false,
      default: null,
      description: 'Sets the `Accept-Language` header.',
    },
    {
      parameter: 'user_agent',
      required: false,
      default: null,
      description:
        'Sets the `User-Agent` header to mimic other devices/browsers.',
    },
    {
      parameter: 'headers',
      required: false,
      default: null,
      description:
        'Sets headers on the request. The string should be URL encoded.',
    },
    {
      parameter: 'cookies',
      required: false,
      default: null,
      description:
        'Sets cookies on the request. The string should be URL encoded.',
    },
    {
      parameter: 'latitude',
      required: false,
      default: null,
      description: 'Latitude used to emulate the Geolocation API.',
    },
    {
      parameter: 'longitude',
      required: false,
      default: null,
      description: 'Longitude used to emulate the Geolocation API.',
    },
    {
      parameter: 'accuracy',
      required: false,
      default: 0,
      description:
        'The accuracy value (in meters) used to emulate the Geolocation API.',
    },
    {
      parameter: 'fail_on_4xx',
      required: false,
      default: null,
      description:
        'If the request to the URL receives a status code 400-451 it will make the API call fail.',
    },
    {
      parameter: 'fail_on_5xx',
      required: false,
      default: null,
      description:
        'If the request to the URL receives a status code 500-511 it will make the API call fail.',
    },
  ],
  errors: [
    {
      code: 400,
      description:
        'Request contains invalid parameters or missing a required parameter.',
    },
    {
      code: 401,
      description: 'Access key is not provided or invalid.',
    },
    {
      code: 402,
      description: 'A failed payment has occurred.',
    },
    {
      code: 403,
      description:
        "Current subscription plan doesn't have permission to perform the request.",
    },
    {
      code: 426,
      description:
        'Monthly quota has been reached. Upgrade your subscription to continue.',
    },
    {
      code: 429,
      description: 'Too many requests hit the API too quickly.',
    },
    {
      code: 500,
      description: "Something bad happened on Snapiant's end. (Rare).",
    },
  ],
  quotas: [
    {
      header: 'X-Quota-Limit',
      description: 'Subscription plan limit',
    },
    {
      header: 'X-Quota-Remaining',
      description:
        'Amount of screenshots remaining until your next billing period',
    },
    {
      header: 'X-Quota-Reset-At',
      description:
        'UNIX timestamp for when the current billing period ends and your remaining screenshots resets',
    },
  ],
}
