const SCREENSHOT_URL = 'https://screenshot.snapiant.com/v1/screenshot'

const curl = `curl --request GET --url ${SCREENSHOT_URL}?access_key=MY_ACCESS_KEY&url=SOME_WEBSITE_URL`

const node = `const fs = require('fs')
const axios = require('axios')

const options = {
  method: 'GET',
  url: '${SCREENSHOT_URL}',
  responseEncoding: 'binary',
  params: {
    url: 'https://some-website.com',
    access_key: 'YOUR_ACCESS_KEY',
  },
}

async function getScreenshot() {
  try {
    const response = await axios(options)
    fs.writeFile('my_screenshot.jpeg', response.data, 'binary', err => {
      if (err) {
        console.log(err)
      } else {
        console.log('saved the screenshot')
      }
    })
  } catch (error) {
    console.log('failed fetching screenshot')
  }
}

getScreenshot()
`

const php = `<?php

$params = http_build_query(array(
    "access_key" => "YOUR_ACCESS_KEY",
    "url" => "https://some-website.com",
    "force" => true
));

$image_data = file_get_contents("${SCREENSHOT_URL}?" . $params);
file_put_contents("my_screenshot.jpeg", $image_data);

?>
`

const python = `from urllib.parse import urlencode
from urllib.request import urlretrieve

params = urlencode({'access_key': 'YOUR_ACCESS_KEY',
                    'url': 'https://some-website.com'})
urlretrieve("${SCREENSHOT_URL}?" +
            params, "my_screenshot.jpeg")

`

const ruby = `require "open-uri"

File.open('my_screenshot.jpeg', 'wb') do |fo|
  params = URI.encode_www_form("access_key" => "YOUR_ACCESS_KEY",
                               "url" => "https://some-website.com",
                               "force" => true)
  fo.write open("${SCREENSHOT_URL}?" + params).read
end
`

const go = `package main

import (
	"io"
	"net/http"
	"os"
)

func main() {
	request, err := http.NewRequest("GET", "${SCREENSHOT_URL}, nil)

	if err != nil {
		panic(err)
	}

	query := request.URL.Query()
	query.Add("access_key", "YOUR_ACCESS_KEY")
	query.Add("url", "https://some-website.com")
	request.URL.RawQuery = query.Encode()

	client := &http.Client{}
	response, err := client.Do(request)

	if err != nil {
		panic(err)
	}

	defer response.Body.Close()

	image, err := os.Create("my_screenshot.jpeg")

	if err != nil {
		panic(err)
	}

	_, err = io.Copy(image, response.Body)

	if err != nil {
		panic(err)
	}

	image.Close()
}
`

export default {
  node,
  curl,
  php,
  python,
  ruby,
  go,
}
