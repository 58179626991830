import React, { useEffect } from 'react'
import {
  Box,
  Code,
  Flex,
  Grid,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import Prism from 'prismjs'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { SideNav } from '../components/side-nav'
import APIDocs from '../utils/api-parameters-docs'
import { CodeSnippet } from '../components/code-snippet'
import CODE_SNIPPETS from '../utils/code-snippets'
import config from '../config'

const SCREENSHOT_URL = `${config.SCREENSHOT_ENDPOINT}/v1/screenshot`

const LINKS = [
  {
    title: 'Getting started',
    href: '#getting-started',
  },
  {
    title: 'Parameters',
    href: '#parameters',
  },
  {
    title: 'Errors',
    href: '#errors',
  },
  {
    title: 'Examples',
    href: '#examples',
  },
  {
    title: 'Quota',
    href: '#quota',
  },
]

const CodeExample = ({ children }) => {
  return (
    <Code my="5" p="5">
      {children}
    </Code>
  )
}

const CodeHighlight = ({ children }) => {
  return (
    <Code color="red.400" display="inline" p="1">
      {children}
    </Code>
  )
}

const Title = ({ children, id, ...rest }) => {
  return (
    <>
      <Box
        as="span"
        id={id}
        sx={{
          display: 'block',
          top: '-100px',
          height: '1px',
          position: 'relative',
        }}
      />
      <Heading
        fontSize="2xl"
        fontWeight="bold"
        mb="6"
        textDecoration="underline"
        {...rest}
      >
        {children}
      </Heading>
    </>
  )
}

const Cell = ({ children, ...rest }) => {
  return (
    <Text p="3" {...rest}>
      {children}
    </Text>
  )
}

const DocumentationPage = ({ location }) => {
  const currentRoute = location?.hash

  useEffect(() => {
    Prism.highlightAll()
  }, [])

  return (
    <Layout>
      <SEO title="Documentation" />
      <Heading as="h1" mb="5">
        Documentation
      </Heading>
      <Flex px="8">
        <SideNav
          currentRoute={currentRoute}
          display={['none', 'none', 'flex']}
          links={LINKS}
          width="20%"
        />

        <Flex
          alignItems="flex-start"
          flexDirection="column"
          maxWidth="4xl"
          width="80%"
        >
          {/* GETTING STARTED  */}

          <Title id="getting-started" mt="3">
            Getting started
          </Title>

          <Text>
            The url format for making a screenshot request to Snapiant via{' '}
            <CodeHighlight>GET</CodeHighlight> or{' '}
            <CodeHighlight>POST</CodeHighlight> request:
          </Text>
          <CodeExample>{SCREENSHOT_URL}</CodeExample>

          <Text>Parameters are passed through a query string:</Text>
          <CodeExample>
            {SCREENSHOT_URL}?param1=somevalue&param2=anothervalue
          </CodeExample>

          <Text>
            You'll also need to add your{' '}
            <CodeHighlight>access_key</CodeHighlight> parameter to authorize
            usage of this service:
          </Text>
          <CodeExample>
            {SCREENSHOT_URL}?access_key=MY_ACCESS_KEY?url=amazon.com
          </CodeExample>

          {/* PARAMETERS */}

          <Title id="parameters" mt="8">
            Parameters
          </Title>
          <Grid gridTemplateColumns="200px 100px 1fr">
            <Cell borderBottom="1px" fontWeight="bold">
              Parameter
            </Cell>
            <Cell borderBottom="1px" fontWeight="bold">
              Default
            </Cell>
            <Cell borderBottom="1px" fontWeight="bold">
              Description
            </Cell>

            {APIDocs.params.map(info => {
              return (
                <React.Fragment key={info.parameter}>
                  <Cell borderBottom="1px">{info.parameter}</Cell>
                  <Cell borderBottom="1px">{info.default}</Cell>
                  <Cell borderBottom="1px">{info.description}</Cell>
                </React.Fragment>
              )
            })}
          </Grid>

          {/* ERRORS */}

          <Title id="errors" mt="8">
            Errors
          </Title>

          <Grid gridTemplateColumns="200px 1fr" width="100%">
            <Cell borderBottom="1px" fontWeight="bold">
              HTTP Status Code
            </Cell>
            <Cell borderBottom="1px" fontWeight="bold">
              Description
            </Cell>

            {APIDocs.errors.map(info => {
              return (
                <React.Fragment key={info.code}>
                  <Cell borderBottom="1px">{info.code}</Cell>
                  <Cell borderBottom="1px">{info.description}</Cell>
                </React.Fragment>
              )
            })}
          </Grid>

          {/* EXAMPLES */}

          <Title id="examples" mt="8">
            Examples
          </Title>

          <Tabs>
            <TabList>
              <Tab>cURL</Tab>
              <Tab>NodeJS</Tab>
              <Tab>Go</Tab>
              <Tab>PHP</Tab>
              <Tab>Python</Tab>
              <Tab>Ruby</Tab>
            </TabList>

            <TabPanels width="3xl">
              <TabPanel>
                <CodeSnippet code={CODE_SNIPPETS.curl} language="bash" />
              </TabPanel>
              <TabPanel>
                <CodeSnippet code={CODE_SNIPPETS.node} language="javascript" />
              </TabPanel>
              <TabPanel>
                <CodeSnippet code={CODE_SNIPPETS.go} language="go" />{' '}
              </TabPanel>
              <TabPanel>
                <CodeSnippet code={CODE_SNIPPETS.php} language="php" />{' '}
              </TabPanel>
              <TabPanel>
                <CodeSnippet code={CODE_SNIPPETS.python} language="python" />{' '}
              </TabPanel>
              <TabPanel>
                <CodeSnippet code={CODE_SNIPPETS.ruby} language="ruby" />{' '}
              </TabPanel>
            </TabPanels>
          </Tabs>

          {/* QUOTA */}

          <Title id="quota" mt="8">
            Quota
          </Title>

          <Grid gridTemplateColumns="200px 1fr" width="100%">
            <Cell borderBottom="1px" fontWeight="bold">
              Header
            </Cell>
            <Cell borderBottom="1px" fontWeight="bold">
              Description
            </Cell>

            {APIDocs.quotas.map(info => {
              return (
                <React.Fragment key={info.header}>
                  <Cell borderBottom="1px">{info.header}</Cell>
                  <Cell borderBottom="1px">{info.description}</Cell>
                </React.Fragment>
              )
            })}
          </Grid>
        </Flex>
      </Flex>
    </Layout>
  )
}

export default DocumentationPage
