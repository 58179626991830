import React from 'react'
import Prism from 'prismjs'
import 'prismjs/components/prism-python'
import 'prismjs/components/prism-ruby'
import 'prismjs/components/prism-go'
import 'prismjs/components/prism-java'
import 'prismjs/components/prism-php'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-csharp'
import 'prismjs/components/prism-markup-templating'

type CodeSnippetProps = {
  language:
    | 'javascript'
    | 'ruby'
    | 'go'
    | 'java'
    | 'bash'
    | 'csharp'
    | 'python'
    | 'php'
  code: string
}

const CodeSnippet = ({ language, code }: CodeSnippetProps) => (
  <pre className={`language-${language}`}>
    <code
      className={`language-${language}`}
      dangerouslySetInnerHTML={{
        __html: Prism.highlight(code, Prism.languages[language], language),
      }}
    />
  </pre>
)

export { CodeSnippet }
